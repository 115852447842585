<template>
    <div class="strategy-index widget">
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <b-button variant="light" class="mr-auto" @click="onExport">
                    Export
                </b-button>
            </div>
        </div>
        <b-table id="strategy-index-table" :items="filterItems()" :fields="columns" responsive class="text-center" show-empty :per-page="50" :current-page="currentPage">
            <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                    <input type="text" v-model="filters[field.key]" placeholder="Search..." class="form-control" />
                </td>
            </template>
            <template slot="empty">
                No results found
            </template>
        </b-table>
        <div class="row mt-3">
            <div class="col-12 d-flex align-items-center justify-content-start">
                <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="50" aria-controls="strategy-index-table"></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { exportCsv } from '@/lib/Exporting';
import { map, clone } from 'lodash';

export default {
    name: 'strategy-index',
    data() {
        let columns = [
            {
                key: 'id',
                label: 'Strategy Number',
                sortable: true
            },
            {
                key: 'internalName',
                label: 'Strategy Name',
                sortable: true
            },
            {
                key: 'publicName',
                label: 'Public Name',
                sortable: true
            }
        ];

        let filters = {};
        for(let column of columns) {
            filters[column.key] = '';
        }

        return {
            columns: columns,
            items: [],
            filters: filters,
            currentPage: 1,
            hasSetupListeners: false
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('strategyindex', this.onStrategyIndex);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('strategyindex', this.onStrategyIndex);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'StrategyIndex',
                args: {}
            });
        },
        onStrategyIndex(event) {
            this.items = event.response;
        },
        filterItems() {
            let filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key => String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase()));
            });

            if(filtered.length > 0) {
                return filtered;
            } else {
                let emptyFilters = {};
                for(let key in this.filters) {
                    emptyFilters[key] = '';
                }

                return [];
            }
        },
        onExport() {
            let headers = {};

            for(let column of this.columns) {
                headers[column.key] = column.label;
            }

            exportCsv(headers, map(this.items, clone), 'Strategy Index');
        }
    }
}
</script>